import { computed, ref, watch } from "vue";
import { defineStore } from "pinia";
import { useStorage } from "@vueuse/core";
import { BigNumber } from "ethers";
import { add, sub } from "date-fns";
import { useRouter } from "vue-router";
import {
  CardType,
  CourtType,
  GameweekStatus,
  Gender,
  NftSkill as TNftSkill,
  RegistrationStatus,
  Scarcity,
  Skill,
} from "fungi-types";
import Step1 from "./steps/OnboardingStep1.vue";
import Step2 from "./steps/OnboardingStep2.vue";
import Step3 from "./steps/OnboardingStep3.vue";
import Step4 from "./steps/OnboardingStep4.vue";
import Step5 from "./steps/OnboardingStep5.vue";
import Step6 from "./steps/OnboardingStep6.vue";
import Step7 from "./steps/OnboardingStep7.vue";
import Step8 from "./steps/OnboardingStep8.vue";
import Step9 from "./steps/OnboardingStep9.vue";
import Step10 from "./steps/OnboardingStep10.vue";
import Step11 from "./steps/OnboardingStep11.vue";
import Step12 from "./steps/OnboardingStep12.vue";
import Step13 from "./steps/OnboardingStep13.vue";
import Step14 from "./steps/OnboardingStep14.vue";
import Step15 from "./steps/OnboardingStep15.vue";
import Step16 from "./steps/OnboardingStep16.vue";
import Step17 from "./steps/OnboardingStep17.vue";
import { IAuctionItem } from "~/common/interfaces/IMarketplace";
import { AuctionStatus } from "~/common/enums/auction";
import { MarketplaceType } from "~/common/enums/marketplace";
import { useStoreApp } from "~/stores/storeApp";
import { onboardingApi } from "~/api/onboarding";
import OnboardingEventType from "~/application/enums/OnboardingEventType";
import useHighlighter from "~/composables/useHighlighter";

const useOnboarding = defineStore("onboarding-steps", () => {
  const router = useRouter();

  const storedStep = useStorage("onboarding-step", 1);
  const skipOnboarding = useStorage("skip-onboarding", false);

  const showOnboarding = ref(false);

  const stepIndex = ref(storedStep.value);

  function recordOnboardingProgress() {
    storedStep.value = stepIndex.value;
    onboardingApi
      .recordEvent(`step_${stepIndex.value}` as OnboardingEventType)
      .catch(console.error);
  }

  watch(stepIndex, () => recordOnboardingProgress());

  const steps = [
    {
      component: Step1,
      coachPosition: "right",
      initiallyTransparentOverlay: false,
      transparentForMs: 0,
      preNavigateTo: {
        name: "Home",
        query: {
          onboarding: "true",
        },
      },
    },
    {
      component: Step2,
      coachPosition: "right",
      initiallyTransparentOverlay: false,
      transparentForMs: 0,
      preNavigateTo: {
        name: "NewCards",
        query: {
          onboarding: "true",
        },
      },
    },
    {
      component: Step3,
      coachPosition: "right",
      initiallyTransparentOverlay: false,
      transparentForMs: 0,
      preNavigateTo: {
        name: "NewCards",
        query: {
          onboarding: "true",
        },
      },
    },
    {
      component: Step4,
      coachPosition: "right",
      initiallyTransparentOverlay: false,
      transparentForMs: 0,
      preNavigateTo: {
        name: "NewCards",
        query: {
          onboarding: "true",
        },
      },
    },
    {
      component: Step5,
      coachPosition: "right",
      initiallyTransparentOverlay: false,
      transparentForMs: 0,
      preNavigateTo: {
        name: "NewCards",
        query: {
          onboarding: "true",
        },
      },
    },
    {
      component: Step6,
      coachPosition: "right",
      initiallyTransparentOverlay: true,
      transparentForMs: 0,
      preNavigateTo: {
        name: "NewCards",
        query: {
          onboarding: "true",
        },
      },
    },
    {
      component: Step7,
      coachPosition: "hidden",
      initiallyTransparentOverlay: false,
      transparentForMs: 0,
      preNavigateTo: {
        name: "NewCards",
        query: {
          onboarding: "true",
        },
      },
    },
    {
      component: Step8,
      coachPosition: "right",
      initiallyTransparentOverlay: false,
      transparentForMs: 0,
      preNavigateTo: {
        name: "NewCards",
        query: {
          onboarding: "true",
        },
      },
    },
    {
      component: Step9,
      coachPosition: "right",
      initiallyTransparentOverlay: true,
      transparentForMs: 0,
      preNavigateTo: {
        name: "Competitions",
        query: {
          onboarding: "true",
        },
      },
    },
    {
      component: Step10,
      coachPosition: "hidden",
      initiallyTransparentOverlay: false,
      transparentForMs: 0,
      noOverlay: true,
      containerClasses: "md:top-0 md:bottom-0 md:right-2 md:left-auto md:justify-end",
      preNavigateTo: {
        path: "/competitions/onboarding",
        query: {
          onboarding: "true",
        },
      },
    },
    {
      component: Step11,
      coachPosition: "hidden",
      initiallyTransparentOverlay: false,
      transparentForMs: 0,
      noOverlay: true,
      containerClasses: "md:top-0 md:bottom-0 md:right-2 md:left-auto md:justify-end",
      preNavigateTo: {
        path: "/competitions/onboarding",
        query: {
          onboarding: "true",
        },
      },
    },
    {
      component: Step12,
      coachPosition: "right",
      initiallyTransparentOverlay: true,
      transparentForMs: 0,
      noOverlay: true,
      containerClasses: "",
      preNavigateTo: {
        path: "/competitions/onboarding",
        query: {
          onboarding: "true",
        },
      },
    },
    {
      component: Step13,
      coachPosition: "right",
      coachMirrored: false,
      initiallyTransparentOverlay: false,
      noOverlay: true,
      transparentForMs: 0,
      preNavigateTo: {
        path: "/competitions/onboarding",
        query: {
          onboarding: "true",
        },
      },
      containerClasses: "pointer-events-auto",
    },
    {
      component: Step14,
      coachPosition: "right",
      coachMirrored: true,
      initiallyTransparentOverlay: false,
      transparentForMs: 0,
      preNavigateTo: {
        path: "/competitions/onboarding",
        query: {
          onboarding: "true",
        },
      },
    },
    {
      component: Step15,
      coachPosition: "left",
      coachSize: "small",
      coachMirrored: true,
      preNavigateTo: {
        path: "/competitions",
        query: {
          onboarding: "true",
        },
      },
      containerClasses: "[&>div.bg-onboarding-overlay]:opacity-0 pointer-events-none allow-scroll",
    },
    {
      component: Step16,
      coachPosition: "left",
      coachMirrored: true,
      noOverlay: true,
      preNavigateTo: {
        name: "MyAccount",
        query: {
          onboarding: "true",
        },
      },
      containerClasses: "pointer-events-auto [&>div.bg-onboarding-overlay]:opacity-70 allow-scroll",
    },
    {
      component: Step17,
      coachPosition: "right",
      coachMirrored: true,
      initiallyTransparentOverlay: true,
      preNavigateTo: {
        name: "MyAccount",
        query: {
          onboarding: "true",
        },
      },
      transparentForMs: 0,
    },
  ];

  const currentStep = computed(() => steps[stepIndex.value - 1]);

  const showOverlay = ref(true);
  watch(currentStep, () => {
    if (currentStep.value.preNavigateTo) {
      router.replace(currentStep.value.preNavigateTo).catch(console.error);
    }

    if (currentStep.value.initiallyTransparentOverlay) {
      showOverlay.value = false;
      setTimeout(() => {
        showOverlay.value = true;
      }, currentStep.value.transparentForMs);
    } else {
      showOverlay.value = true;
    }
  });

  const demoCard: TNftSkill & { highlight: boolean } = {
    firstName: "Ugo",
    lastName: "Humbert",
    skill: Skill.POWER,
    scarcity: Scarcity.GOLD,
    gender: Gender.MALE,
    card_type: CardType.NftSkills,
    courtType: CourtType.MULTI,
    numSeries: 2,
    age: 26,
    countryIso: "FRA",
    maxSeries: 10,
    skillLevel: 2,
    score: 67,
    image: "https://nft.fungiball.io/skills/10062.png",
    tokenId: 10062,
    season: 2024,
    slug: "ugo-humbert-gold-2024-0-10",
    title: "Ugo Humbert - Gold - 2024",
    owner: "",
    ownedBy: null,
    highlight: true,
  };

  const skillsDemoCards: TNftSkill[] = [
    // Service
    {
      ...demoCard,
      skill: Skill.SERVICE,
      image: "https://nft.fungiball.io/skills/4800.png",
      scarcity: Scarcity.SILVER,
    },
    // Power
    {
      ...demoCard,
      skill: Skill.POWER,
      image: "https://nft.fungiball.io/skills/7858.png",
      scarcity: Scarcity.SILVER,
    },
    // Return
    {
      ...demoCard,
      skill: Skill.RETURN,
      image: "https://nft.fungiball.io/skills/6999.png",
      scarcity: Scarcity.SILVER,
    },
    // Mental
    {
      ...demoCard,
      skill: Skill.MENTAL,
      image: "https://nft.fungiball.io/skills/822.png",
      scarcity: Scarcity.SILVER,
    },
  ];

  const rarityDemoCards: TNftSkill[] = [
    {
      ...demoCard,
      firstName: "Borna",
      lastName: "Coric",
      skill: Skill.POWER,
      scarcity: Scarcity.SILVER,
      countryIso: "HRV",
      tokenId: 6428,
      image: "https://nft.fungiball.io/skills/6428.png",
      score: 62,
    },
    {
      ...demoCard,
      firstName: "Borna",
      lastName: "Coric",
      skill: Skill.POWER,
      scarcity: Scarcity.GOLD,
      countryIso: "HRV",
      tokenId: 7244,
      image: "https://nft.fungiball.io/skills/7244.png",
      score: 64,
    },
    {
      ...demoCard,
      firstName: "Borna",
      lastName: "Coric",
      skill: Skill.POWER,
      scarcity: Scarcity.PLATINUM,
      countryIso: "HRV",
      tokenId: 38,
      image: "https://nft.fungiball.io/skills/38.png",
      score: 67,
    },
  ];

  const demoAuction: IAuctionItem & { onboarding: true } = {
    card: demoCard,
    status: AuctionStatus.IN_PROGRESS,
    cuid: "__DEMO_AUCTION_CUID__",
    startedAmount: BigNumber.from("3000000000000000000").toString(),
    startedAt: sub(new Date(), { hours: 23, minutes: 49 }).toISOString(),
    endedAt: add(new Date(), { minutes: 60 }).toISOString(),
    type: CardType.NftSkills,
    countOrders: 0,
    lastBidder: null,
    extraTime: 0,
    lastBidAmount: null,
    lastBidAt: null,
    marketType: MarketplaceType.PRIMARY,
    onboarding: true,
  };

  function goToNextStep() {
    if (stepIndex.value < steps.length) {
      stepIndex.value++;
    } else {
      stepIndex.value++;
      exitOnboarding();

      if (router.currentRoute.value.query.onboarding === "true") {
        router
          .replace({
            ...router.currentRoute.value,
            query: {
              ...router.currentRoute.value.query,
              onboarding: "false",
            },
          })
          .catch(console.error);
      }
    }
  }

  function goToPreviousStep() {
    if (stepIndex.value > 1) {
      stepIndex.value--;
    }
  }

  function exitOnboarding() {
    useHighlighter().removeAllOverlays();

    showOnboarding.value = false;
    skipOnboarding.value = true;

    useStoreApp().setBidModalState(false);

    onboardingApi.recordEvent(OnboardingEventType.IGNORED).catch(console.error);

    router
      .replace({
        name: "MyAccount",
      })
      .catch(console.error);
  }

  function startOnboarding() {
    showOnboarding.value = !skipOnboarding.value;
    demoAuction.startedAt = sub(new Date(), { hours: 23, minutes: 49 }).toISOString();
    demoAuction.endedAt = add(new Date(), { minutes: 60 }).toISOString();

    if (currentStep.value.preNavigateTo) {
      router.push(currentStep.value.preNavigateTo).catch(console.error);
    }
  }

  function restartOnboarding() {
    storedStep.value = 1;
    skipOnboarding.value = false;

    demoAuction.startedAt = sub(new Date(), { hours: 23, minutes: 49 }).toISOString();
    demoAuction.endedAt = add(new Date(), { minutes: 60 }).toISOString();

    router.replace(steps[0].preNavigateTo!).catch(console.error);

    stepIndex.value = 1;
    showOnboarding.value = true;

    onboardingApi.recordEvent(OnboardingEventType.RESTARTED).catch(console.error);
  }

  function hideOnboarding() {
    showOnboarding.value = false;
  }

  function setStep(step: number) {
    if (step < 0 || step > 17) {
      console.warn(`Attempted to set an invalid onboarding step (${step})`);
      return;
    }

    stepIndex.value = step;
  }

  const onboardingSeasons = computed(() => [
    {
      id: "onboarding",
      name: "World Tour Men Gold",
      gameweek_id: "onboarding",
      gameweek: {
        status: GameweekStatus.Upcoming,
      },
      competition: {
        gender: "male",
        scarcity: Scarcity.GOLD,
        rules: { gender: { male: { max: 4 } }, scarcity: { gold: { max: 4 } } },
      },
      user_registration: {
        id: "onboarding",
        status: RegistrationStatus.Draft,
        build: {
          power: stepIndex.value >= 12 ? demoCard.tokenId : null,
          service: 5166,
          return: 599,
          mental: 8116,
        },
      },
      registrations_count: 30,
      prize_money: 700,
      surface: "hard_outdoor",
      cards: {
        power: {
          ...demoCard,
          sinergy: { country: true, courtType: true },
        },
        service: {
          ...demoCard,
          skill: Skill.SERVICE,
          courtType: CourtType.CLAY,
          countryIso: "DEU",
          tokenId: 5166,
          score: 64,
          image: "https://nft.fungiball.io/skills/5166.png",
          sinergy: { country: true, courtType: true },
        },
        return: {
          ...demoCard,
          skill: Skill.RETURN,
          courtType: CourtType.GRASS,
          countryIso: "ITA",
          tokenId: 599,
          score: 70,
          image: "https://nft.fungiball.io/skills/599.png",
          sinergy: { country: false, courtType: true },
        },
        mental: {
          ...demoCard,
          skill: Skill.MENTAL,
          courtType: CourtType.GRASS,
          countryIso: "FRA",
          tokenId: 8116,
          score: 54,
          image: "https://nft.fungiball.io/skills/8116.png",
          sinergy: { country: false, courtType: true },
        },
      },
    },
    {
      id: "onboarding2",
      name: "World Tour Men Silver",
      gameweek_id: "onboarding",
      gameweek: {
        status: GameweekStatus.Upcoming,
      },
      competition: {
        gender: "male",
        scarcity: Scarcity.GOLD,
      },
      user_registration: {
        id: "onboarding",
        status: RegistrationStatus.Draft,
        build: {
          power: stepIndex.value >= 12 ? demoCard.tokenId : null,
          service: 5166,
          return: 599,
          mental: 8116,
        },
      },
      registrations_count: 30,
      prize_money: 700,
      surface: "clay_outdoor",
    },
    {
      id: "onboarding3",
      name: "World Tour Mixed",
      gameweek_id: "onboarding",
      gameweek: {
        status: GameweekStatus.Upcoming,
      },
      competition: {
        gender: "male",
        scarcity: Scarcity.GOLD,
      },
      user_registration: {
        id: "onboarding",
        status: RegistrationStatus.Draft,
        build: {
          power: stepIndex.value >= 12 ? demoCard.tokenId : null,
          service: 5166,
          return: 599,
          mental: 8116,
        },
      },
      registrations_count: 30,
      prize_money: 700,
      surface: "grass_outdoor",
    },
  ]);

  const registration = computed(() => onboardingSeasons.value[0]);

  return {
    showOnboarding,
    showOverlay,
    step: stepIndex,
    totalSteps: steps.length,
    currentStep,
    steps,
    demoCard,
    demoAuction,
    skillsDemoCards,
    rarityDemoCards,
    registration,
    onboardingSeasons,
    goToNextStep,
    goToPreviousStep,
    exitOnboarding,
    startOnboarding,
    restartOnboarding,
    hideOnboarding,
    setStep,
  };
});

export default useOnboarding;
