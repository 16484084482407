<script setup lang="ts">
import { useI18n } from "vue-i18n";
import Trans from "~/modules/translations";

import AccountWidget from "./AccountWidget.vue";

import Footer from "./Footer.vue";
import SubHeader from "./subHeaders/index.vue";
import { useRoute, useRouter } from "vue-router";
import { computed, onBeforeMount, onMounted, onUnmounted, ref } from "vue";
import { storeToRefs } from "pinia";
import MobileNavBar from "./navigation/MobileNavBar.vue";
import { useStoreFlashSale } from "~/stores/storeFlashSale";
import { useStoreUserV2 } from "~/stores/storeUserV2";
import dayjs from "dayjs";
import { X } from "lucide-vue-next";
import { useMediaQuery } from "~/composables/useMediaQuery";

const userStore = useStoreUserV2();
const storeFlashSale = useStoreFlashSale();
const { homeFlashSale, bannerImg } = storeToRefs(storeFlashSale);

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const showBanner = ref(true);
const isMobile = useMediaQuery("(max-width: 800px)");

function goToFlashSale() {
  if (homeFlashSale.value?.name) {
    router.push(
      Trans.i18nRoute({ name: "FlashSale", params: { name: homeFlashSale.value?.name } })
    );
  }
}

const homeFlashHasStarted = computed(() => {
  if (!homeFlashSale.value) {
    return false;
  }
  const startDate = dayjs(homeFlashSale.value.startedAt);
  return dayjs().isAfter(startDate);
});

const setItem = (key: string, value: string) => localStorage.setItem(key, value);
const getItem = (key: string) => localStorage.getItem(key) || "";

const flashSaleId = homeFlashSale.value?.cuid || "";

const handleHideBanner = () => {
  showBanner.value = false;
  if (!isMobile.value) {
    setItem("hideFlashSaleId", flashSaleId);
  }
};

const isRegistered = computed(() => route.name === "Register");

onBeforeMount(() => {
  const show = getItem("hideFlashSaleId");
  showBanner.value = show === flashSaleId ? false : true;
});

const navItems = [
  {
    title: "nav.play",
    name: "Competitions",
  },
  {
    title: "nav.market",
    name: "Marketplace",
  },
  {
    title: "nav.myCards",
    name: "MyCards",
  },
  {
    title: "Blog",
    name: "Blog",
  },
  {
    title: "nav.dashboard",
    name: "Dashboards",
  },
];

const showBackgroundNav = ref(true);

const checkScroll = () => {
  if (window.scrollY > 130) {
    if (showBackgroundNav.value === false) {
      showBackgroundNav.value = true;
    }
  } else {
    if (showBackgroundNav.value === true) {
      showBackgroundNav.value = false;
    }
  }
};

// Handle window resize
onMounted(() => {
  checkScroll();
  window.addEventListener("scroll", checkScroll);
});

onUnmounted(() => {
  window.removeEventListener("scroll", checkScroll);
});

const showHeader = computed(() => {
  return !["Subscription"].includes(route.name as string);
});

const showFooter = computed(() => {
  return !["Subscription", "Login"].includes(route.name as string);
});

const showFooterMobile = computed(() => {
  return !["Subscription", "Home", "Login"].includes(route.name as string);
});

const routeName = route.name as string;
</script>

<template>
  <!-- For scrolling -->
  <div
    v-if="
      showBanner &&
      !['FlashSale', 'Subscription'].includes($route.name as string) &&
      homeFlashHasStarted
    "
    :class="`bg-no-repeat hover:cursor-pointer text-xs md:text-lg lg:text-lg bg-[#0b1114] h-20 flex items-center uppercase cursor-pointer justify-between border-[1px] border-slate-700 fixed top-0 w-full z-40`"
    :style="{
      backgroundImage: bannerImg,
      backgroundSize: 'auto 100%',
      backgroundPosition: 'center left',
    }"
    @click="goToFlashSale"
  >
    <div
      class="flex flex-col mx-auto text-shadow font-light font-display tracking-widest lg:font-normal lg:tracking-normal"
      :class="isMobile ? 'text-left pl-4 pr-3' : 'text-center'"
    >
      <span class="text-shadow">{{ $t("pages.marketplace.flashSale.banner.title") }}</span>
      <span class="text-shadow">{{ $t("pages.marketplace.flashSale.banner.subtitle") }}</span>
    </div>

    <X v-if="!isMobile" class="mr-3" @click.stop="handleHideBanner" />
  </div>
  <div v-if="isRegistered"></div>
  <div
    v-else-if="showHeader"
    :class="`${showBackgroundNav ? 'bg-black pt-3' : ''} ${route.name !== 'FlashSale' && showBanner && homeFlashHasStarted ? 'top-[80px]' : 'top-[0]'} transition-all flex flex-row uppercase p-6 pb-4 fixed left-0 right-0 bg-gradient-to-b from-black from-0% via-black/50 via-40% to-black/0 lg:justify-between justify-between items-center z-30`"
  >
    <div v-if="!userStore.mustRegister" class="flex-row flex gap-10 items-center">
      <router-link to="/"><icon-fg-logo /></router-link>
      <template class="hidden lg:flex flex-row">
        <div class="border-b-white/25 flex flex-row gap-10 border-b-[1px] h-fit lg:gap-5 xl:gap-10">
          <router-link
            v-for="item in navItems"
            :key="item.name"
            :to="Trans.i18nRoute({ name: item.name })"
            class="nav-item tracking-widest text-sm font-semibold pb-1 px-2.5 border-b-[2px] border-transparent hover:border-[#EDFF00]"
          >
            {{ t(item.title) }}
          </router-link>
        </div>
        <button
          v-if="homeFlashHasStarted"
          class="hidden d-xs:flex btn-secondary items-center px-f2 font-sans small-text font-semibold gap-f2 ml-2"
          @click="goToFlashSale"
        >
          <icon-fg-lightning />
          <span class="max-d-s:hidden">{{ $t("pages.marketplace.flashSale.isLive") }}</span>
          <span class="d-s:hidden">{{ $t("pages.marketplace.flashSale.isLiveShort") }}</span>

          <icon-ic:round-chevron-right class="bounce max-d-s:hidden" />
        </button>
      </template>
    </div>
    <AccountWidget v-if="!userStore.mustRegister" />
  </div>
  <div
    :class="`min-h-screen ${!['FlashSale', 'Subscription', 'Home', 'Login'].includes($route.name as string) && showBanner && homeFlashHasStarted ? 'mt-[80px]' : 'top-[0]'} items-stretch flex-col`"
  >
    <div class="relative">
      <SubHeader />
      <router-view></router-view>
    </div>
    <div>
      <Footer v-if="showFooter" />
    </div>
  </div>
  <MobileNavBar class="z-50" v-if="showFooterMobile && !isRegistered" />
</template>

<style scoped lang="postcss">
.router-link-active {
  @apply border-[#EDFF00];
}

.text-shadow {
  text-shadow: 0 0 5px rgb(0, 0, 0, 1);
}
</style>
