<script setup lang="ts">
import { useStoreUserV2 } from "@/stores/storeUserV2";
import { computed } from "vue";
import { useTransak } from "@/composables/useTransak";
import router from "@/router";

import Discord from "/icon-discord.svg?url";
import Twitter from "/icon-twitter.svg?url";

import Trans from "~/modules/translations";
const storeUser = useStoreUserV2();

const routeName = computed(() => (router.currentRoute.value.name as string) || "");
const { showOverlay } = useTransak();
const addMatic = () => {
  const mode = window.innerWidth > 1024 ? "desktop" : "mobile";
  if (storeUser.isWalletConnected) showOverlay(mode);
  else router.push(Trans.i18nRoute({ name: "Login" }));
};
async function logout() {
  await storeUser.logout();
  router.push(Trans.i18nRoute({ name: "Home" }));
}

const displayLogout = computed<boolean>(() => ["MyAccount"].includes(routeName.value));
const displaySignIn = computed<boolean>(() => !["MyAccount", "Login"].includes(routeName.value));
</script>

<template>
  <!-- Account status -->
  <div
    class="flex bg-primary-110 bg-opacity-50 rounded-[68px]"
    v-if="storeUser.isWalletConnected && !displayLogout"
  >
    <div class="px-f3 flex items-center">
      <div
        @click="addMatic"
        class="w-f4 h-f4 flex items-center justify-center rounded-full bg-white hover:bg-primary bg-opacity-10 cursor-pointer"
      >
        <icon-fg-add />
      </div>
      <p class="font-medium pt-[1px] pl-f3 pr-f1">
        {{ storeUser.availableWMaticBalance.toFixed(2) }}
      </p>
      <icon-fgc-w-matic class="w-f3 h-f3" />
    </div>
    <router-link :to="Trans.i18nRoute({ name: 'MyAccount' })" class="flex items-center gap-f2">
      <p class="overflow-hidden font-display uppercase whitespace-nowrap text-ellipsis max-w-32">
        {{ storeUser.username }}
      </p>
      <img
        src="/images/avatar.png"
        alt="avatar"
        width="110"
        height="110"
        class="w-f5 h-f5 rounded-full"
      />
    </router-link>
  </div>
  <div v-else-if="displayLogout" class="flex min-w-f7">
    <button class="btn-ghost btn-small w-full text-center" @click="logout">
      {{ $t("nav.logout") }}
    </button>
  </div>
  <div v-else-if="displaySignIn" class="flex gap-4 items-center">
    <div class="flex flex-row gap-2">
      <a
        href="https://x.com/Fungiball_off"
        target="_blank"
        class="p-2 border-[1px] bg-black/50 flex px-2.5 items-center border-gray-400 rounded-full hover:cursor-pointer hover:border-white transition-colors"
      >
        <img :src="Twitter" alt="Twitter" class="h-4" />
      </a>
      <a
        href="https://discord.gg/YJJw7RP33K"
        target="_blank"
        class="p-2 border-[1px] bg-black/50 flex items-center border-gray-400 rounded-full hover:cursor-pointer hover:border-white transition-colors"
      >
        <img :src="Discord" alt="Discord" class="h-5" />
      </a>
    </div>
    <router-link
      :class="`btn-primary items-center flex px-10 rounded-md tracking-wider text-center ${routeName === 'Home' ? 'hidden' : ''} lg:block`"
      :to="Trans.i18nRoute({ name: 'Login' })"
      >{{ $t("nav.login") }}</router-link
    >
  </div>
</template>

<style scoped></style>
